import React, { useState } from "react";
import { Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import PrimaryButton from "library/buttons/PrimaryButton";
import TextFieldSimple from "library/form/OutPointTextField";
import OutPointModal from "library/surface/ModalCentered";
import Header3 from "library/text/headers/Header3";
import OutPointSelector from "library/form/OutPointSelector";
import BodyText from "library/text/body/BodyText";
import { makeAuthenticatedPostRequest } from "utils/backend-api";
import { getJesterData } from "redux/jesterSlice";
import PlatformCardModal from "./PlatformCardModal/PlatformCardModal";

const styles = {
  modal: {
    width: "400px",
    display: "flex",
    flexDirection: "column",
  },
  textfield: {
    width: "100%",
    marginBottom: "20px",
  },
  nextButton: {
    width: "100px",
    marginLeft: "auto",
  },
  header: {
    marginBottom: "20px",
  },
};

export default function AddCredentialsButton({
  channel,
  whereFrom,
  tokenType,
}) {
  const integrationLevelChannelData =
    useSelector((state) => state.jester)?.data?.integration_level?.[
      tokenType
    ]?.[channel] || [];
  const supportsPagePosts = tokenType === "page";
  const dispatch = useDispatch();

  const [description, setDescription] = useState("");
  const [parentId, setParentId] = useState("");
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [platformModal, setPlatformModal] = useState(false);

  const isDescriptionUnique = integrationLevelChannelData
    ? integrationLevelChannelData.reduce(
        (acc, credentials) => description !== credentials.description && acc,
        true,
      )
    : true;

  const adCredentialsForChannel =
    useSelector((state) => state.jester)?.data?.integration_level?.ad?.[
      channel
    ] || [];
  // eslint-disable-next-line no-shadow
  const options = adCredentialsForChannel.map(({ description }) => description);
  const selectedName = adCredentialsForChannel.reduce(
    // eslint-disable-next-line no-shadow
    (name, { id, description }) => (parentId === id ? description : name),
    "",
  );

  return (
    <>
      <PlatformCardModal
        whereFrom={whereFrom}
        channel={channel}
        credentials={{ parent_credential_id: parentId }}
        description={description}
        tokenType={tokenType}
        modalOpen={platformModal}
        setModalOpen={setPlatformModal}
      />

      <OutPointModal
        sx={styles.modal}
        modalOpen={descriptionModal}
        setModalOpen={setDescriptionModal}
        closeButton={false}
      >
        <Header3 sx={styles.header}>{`New ${tokenType} integration`}</Header3>
        <TextFieldSimple
          sx={styles.textfield}
          name="Enter account name"
          variant="standard"
          value={description}
          error={!isDescriptionUnique}
          errorMsg="Account name must be unique amongst names for this channel."
          onChange={(text) => setDescription(text)}
        />
        {tokenType === "page" && (
          <>
            <BodyText>Link to ad credential</BodyText>
            <OutPointSelector
              onChange={(selectedDescription) => {
                const selectedCredential = adCredentialsForChannel.find(
                  // eslint-disable-next-line no-shadow
                  ({ description }) => description === selectedDescription,
                );
                setParentId(selectedCredential?.id);
              }}
              menuItems={options}
              selectedValue={selectedName}
            />
          </>
        )}
        <PrimaryButton
          sx={styles.nextButton}
          disabled={
            !description ||
            !isDescriptionUnique ||
            (supportsPagePosts && !parentId)
          }
          onClick={async () => {
            try {
              await makeAuthenticatedPostRequest("integrations", {
                description,
                token_type: tokenType,
                channel,
                parent_credential_id: parentId,
              });
              dispatch(getJesterData());
              setDescriptionModal(false);
              setPlatformModal(true);
            } catch (e) {
              // Note: Error is already logged by makeAuthenticatedRequest
            }
          }}
        >
          Next
        </PrimaryButton>
      </OutPointModal>

      <Button
        size="small"
        onClick={() => {
          setDescription("");
          setDescriptionModal(true);
        }}
        variant="outlined"
        color="primary"
      >
        + Add
      </Button>
    </>
  );
}
