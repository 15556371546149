import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { capitalize } from "lodash";
import Button from "@mui/material/Button";
import BodyText from "library/text/body/BodyText";
import { LoadingButton } from "@mui/lab";
import PrimaryButton from "library/buttons/PrimaryButton";
import OutPointModal from "library/surface/ModalCentered";
import { jesterPostRequest } from "utils/jester-api";
import { getJesterData } from "redux/jesterSlice";
import { getIngestionData } from "redux/ingestionSlice";
import { PERIWINKLE_BLUE } from "assets/palette";
import IconText from "library/containers/IconText";
import Header2 from "library/text/headers/Header2";
import Header3 from "library/text/headers/Header3";
import { cardData } from "pages/connections/data/connectionsData";
import LogoChip from "../../connections/components/LogoChip";
import AdsetTaggingForm from "./AdsetTaggingForm";
import {
  formatKpiForDisplay,
  unformatKpi,
} from "../../../utils/data/formatters";
import TargetMetricForm from "../../connections/components/PlatformCardModal/TargetMetricForm";
import Spinner from "../../../library/progress/Spinner";

const styles = {
  targetValueSelector: {
    borderRadius: "5px",
    height: "40px",
    marginTop: "6px",
  },
  formField: {
    width: "100%",
    marginBottom: "10px",
  },
  adsetModButton: {
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  closeButton: {
    width: "175px",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  textContainer: {
    width: "100%",
    marginTop: "16px",
    marginBottom: "16px",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: "35px",
    justifyContent: "space-between",
  },
  hyperlink: {
    color: PERIWINKLE_BLUE,
    fontWeight: 600,
  },
  iconContainer: {
    display: "flex",
    width: "100%",
    marginBottom: "10px",
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  line: {
    borderTop: "1px solid #E3E6EC",
    width: "100%",
    margin: "20px 0 20px 0",
  },
  logoChip: {
    margin: "0 15px 10px 0",
  },
};

function AdsetModifierComponent({ rowData }) {
  const [serialisedTags, setSerialisedTags] = useState(rowData.tags ?? "");
  const [kpi, setKpi] = useState(rowData.target_metric ?? "");
  const [benchmark, setBenchmark] = useState(rowData.benchmark_value ?? "");
  const [impressionThreshold, setImpressionThreshold] = useState(
    rowData.impression_threshold ?? "",
  );
  const [buyingUnit, setBuyingUnit] = useState(rowData.buying_unit ?? "");
  const [isBenchmarkError, setIsBenchmarkError] = useState(false);
  const [isImpressionThresholdError, setIsImpressionThresholdError] =
    useState(false);
  const [isBuyingUnitError, setIsBuyingUnitError] = useState(false);

  const [isRequestPending, setIsRequestPending] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const metricsData = useSelector((state) => state.metrics)?.data || [];
  const displayMetricsData = metricsData.filter(
    (metric) => metric.channel === null || metric.channel === rowData.channel,
  );

  const closeModals = () => setIsModalOpen(false);

  const refetchData = () => {
    dispatch(getJesterData());
    dispatch(getIngestionData());
  };

  const makeRequestBody = () => ({
    token_type: "ad",
    channel: rowData.channel,
    description: rowData.credential,
    target_metric: {
      adset_id: rowData.adset_id,
      credential_id: rowData.credential_id,
      target_metric: unformatKpi(kpi || rowData.target_metric),
      benchmark_value: benchmark || rowData.benchmark_value,
      impression_threshold: impressionThreshold || rowData.impression_threshold,
      buying_unit: buyingUnit || rowData.buying_unit,
      is_integration_level_change: false,
    },
  });

  const getColor = (colorRowData) => {
    const { status } = colorRowData;
    let color;
    switch (status) {
      case "ACTIVE":
        color = "#2C985F";
        break;
      case "PAUSED":
        color = "#D06A15";
        break;
      default:
        color = "black";
    }

    return color;
  };

  const textDisplay = (
    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
      <BodyText sx={{ color: getColor(rowData) }}>
        {capitalize(rowData.status.toLowerCase())}
      </BodyText>
      <BodyText noWrap>{rowData.adset_name}</BodyText>
    </div>
  );

  const updateAdset = async () => {
    setIsRequestPending(true);
    try {
      const requestBody = makeRequestBody();
      await jesterPostRequest("metadata", requestBody);
      setIsRequestPending(false);
      closeModals();
      refetchData();
      return true;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setIsRequestPending(false);
      closeModals();
      return false;
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components, no-shadow
  function ContinueButton({ children, ...props }) {
    return isRequestPending ? (
      <LoadingButton loading {...props}>
        {children}
      </LoadingButton>
    ) : (
      <PrimaryButton {...props}>{children}</PrimaryButton>
    );
  }

  const topBanner = (
    <div style={styles.textContainer}>
      <IconText sx={styles.iconContainer}>
        <LogoChip
          sx={styles.logoChip}
          logo={cardData[rowData.channel]?.logo}
          channel={rowData.channel}
        />
        <Header2
          noWrap={false}
          sx={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-all",
            width: "100%",
          }}
        >
          {rowData.adset_name}
          {rowData.description}
        </Header2>
      </IconText>
      <Header3>Update adset:</Header3>
    </div>
  );

  const form = (
    <>
      <TargetMetricForm
        displayMetricsData={displayMetricsData}
        kpi={formatKpiForDisplay(kpi)}
        setKpi={setKpi}
        benchmark={benchmark}
        setBenchmark={setBenchmark}
        impressionThreshold={impressionThreshold}
        setImpressionThreshold={setImpressionThreshold}
        buyingUnit={buyingUnit}
        setBuyingUnit={setBuyingUnit}
        isBenchmarkError={isBenchmarkError}
        setIsBenchmarkError={setIsBenchmarkError}
        isImpressionThresholdError={isImpressionThresholdError}
        setIsImpressionThresholdError={setIsImpressionThresholdError}
        isBuyingUnitError={isBuyingUnitError}
        setIsBuyingUnitError={setIsBuyingUnitError}
      />
      <AdsetTaggingForm
        rowData={rowData}
        serialisedTags={serialisedTags}
        setSerialisedTags={setSerialisedTags}
      />
      <div style={styles.buttonContainer}>
        <PrimaryButton
          sx={styles.closeButton}
          variant="text"
          color="primary"
          onClick={closeModals}
        >
          Cancel
        </PrimaryButton>
        <ContinueButton sx={styles.closeButton} onClick={updateAdset}>
          Continue
        </ContinueButton>
      </div>
    </>
  );

  const adsetButton = (
    <>
      <OutPointModal modalOpen={isModalOpen} setModalOpen={setIsModalOpen}>
        {topBanner}
        {form}
      </OutPointModal>
      <Button
        variant="outlined"
        sx={styles.adsetModButton}
        onClick={() => setIsModalOpen(!isModalOpen)}
      >
        {textDisplay}
      </Button>
    </>
  );

  return isRequestPending ? <Spinner /> : adsetButton;
}

export default AdsetModifierComponent;
