/* eslint-disable no-unused-vars */
import React from "react";
import LogoChip from "pages/connections/components/LogoChip";
import { cardData } from "pages/connections/data/connectionsData";
import { capitalize } from "lodash";
import { strpdate } from "utils/data/dates";
import { subDays } from "date-fns";
import { addCommasToDecimal, addCommasToNumber } from "utils/data/strings";
import TableCellDate from "../components/TableCellDate";
import BoostButton from "../components/BoostButton";
import ExtendButton from "../components/ExtendButton";
import StopButton from "../components/StopButton";
import InfoButton from "../components/InfoButton";
import AdsetModifierComponent from "../components/AdsetModifierComponent";
import { formatKpiForDisplay } from "../../../utils/data/formatters";
import AdsetBrandPicker from "../components/AdsetBrandPicker";
import "../styles/dataGridStyles.css";

const gridColumns = [
  {
    headerName: "",
    field: "channel",
    align: "center",
    cellClassName: "align-center",
    headerClassName: "header",
    resizable: false,
    sortable: false,
    renderCell: ({ value }) => (
      <LogoChip
        logo={cardData[value]?.logo}
        channel={value}
        size="20px"
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      />
    ),
  },
  {
    headerName: "Adset Name",
    field: "adset_name",
    headerClassName: "header",
    align: "left",
    cellClassName: "align-center",
    sortable: false,
    renderCell: ({ row }) => {
      return <AdsetModifierComponent rowData={row} />;
    },
  },
  {
    headerName: "Adset Brand",
    field: "adset_brand",
    headerClassName: "header",
    align: "center",
    headerAlign: "center",
    cellClassName: "align-center",
    sortable: false,
    renderCell: ({ row }) => <AdsetBrandPicker rowData={row} />,
  },
  {
    headerName: "Campaign Name",
    field: "campaign_name",
    headerClassName: "header",
    align: "left",
  },
  {
    headerName: "Impressions",
    field: "impressions",
    headerClassName: "header",
    headerAlign: "center",
    renderCell: ({ value }) => addCommasToNumber(value),
  },
  {
    headerName: "Spend",
    field: "spend",
    headerClassName: "header",
    headerAlign: "center",
    renderCell: ({ value }) => `$${addCommasToDecimal(value)}`,
  },
  {
    headerName: "Key KPI",
    field: "target_metric",
    headerClassName: "header",
    headerAlign: "center",
    renderCell: ({ value }) => {
      const text = value.length <= 4 ? value.toUpperCase() : capitalize(value);
      return text;
    },
  },
  {
    headerName: "KPI Result",
    field: "target_metric_value",
    align: "left",
    headerClassName: "header",
    headerAlign: "center",
    renderCell: ({ value, row }) => {
      if (value === null || value === undefined) {
        return "-";
      }

      if (row.is_cost) {
        return `$${addCommasToDecimal(value)}`;
      }
      if (["enr", "ctr", "vtr"].includes(row.target_metric)) {
        return `${addCommasToDecimal(value)}%`;
      }
      return addCommasToNumber(value);
    },
  },
  {
    headerName: "Result Name",
    field: "result",
    align: "right",
    headerClassName: "header",
    headerAlign: "center",
    renderCell: ({ value }) => (value ? formatKpiForDisplay(value) : "-"),
  },
  {
    headerName: "Result Value",
    field: "result_value",
    align: "left",
    headerClassName: "header",
    headerAlign: "center",
    renderCell: ({ value }) => {
      if (value === null || value === undefined) {
        return "-";
      }
      return addCommasToNumber(value);
    },
  },
  {
    headerName: "Start",
    field: "start_time",
    headerClassName: "header",
    headerAlign: "center",
    cellClassName: "align-center",
    renderCell: ({ value }) => (value ? <TableCellDate date={value} /> : "-"),
    sortComparator: (v1, v2) => new Date(v1) - new Date(v2),
  },
  {
    headerName: "End",
    field: "end_time",
    headerClassName: "header",
    headerAlign: "center",
    cellClassName: "align-center",
    renderCell: ({ value }) => (value ? <TableCellDate date={value} /> : "-"),
    sortComparator: (v1, v2) => new Date(v1) - new Date(v2),
  },
  {
    headerName: "Budget",
    field: "lifetime_budget",
    headerClassName: "header",
    headerAlign: "center",
    renderCell: ({ value }) => `$${addCommasToDecimal(value)}`,
  },
  {
    headerName: "Boost",
    field: "boost_amount",
    headerClassName: "header",
    headerAlign: "center",
    renderCell: ({ value }) => (value ? `$${addCommasToDecimal(value)}` : "-"),
    align: "center",
  },
  {
    headerName: "Rank",
    field: "rank",
    headerClassName: "header",
    headerAlign: "center",
    renderCell: ({ value }) =>
      value || value === 0 ? `${addCommasToDecimal(value)}%` : "-",
    align: "center",
  },
  {
    headerName: "",
    field: "adset_id",
    align: "left",
    sortable: false,
    headerClassName: "header",
    resizable: false,
    headerAlign: "center",
    renderCell: ({ row }) => {
      const yesterday = subDays(Date.now(), 1);
      const isTooSoonToBoost =
        strpdate(row.most_recent_boost_created_at) > yesterday;
      if (row.boost_amount) {
        return (
          <BoostButton
            channel={row.channel}
            investment={row.boost_amount.toFixed(2)}
            currentBudget={row.lifetime_budget}
            recommendedOn={row.recommendation_time}
            adsetId={row.api_adset_id}
            adsetName={row.adset_name}
            adsetEndTime={row.end_time}
            disableDateTimePicker={row.channel === "google"}
            disabled={isTooSoonToBoost}
          />
        );
      }
      if (row.reached_threshold) {
        if (row.status === "ACTIVE") {
          return (
            <StopButton
              channel={row.channel}
              adsetId={row.api_adset_id}
              adsetName={row.adset_name}
              recommendedOn={row.recommendation_time}
              disabled={row.channel === "google"}
              currentBudget={row.lifetime_budget}
              boostAmount={row.boost_amount}
              submittedOn={row.start_time}
              expiredOn={row.end_time}
            />
          );
        }
        return (
          <InfoButton
            channel={row.channel}
            currentBudget={row.lifetime_budget}
            adsetId={row.api_adset_id}
            adsetName={row.adset_name}
            boostAmount={row.boost_amount}
            submittedOn={row.start_time}
            expiredOn={row.end_time}
          />
        );
      }
      return (
        <ExtendButton
          channel={row.channel}
          currentBudget={row.lifetime_budget}
          adsetId={row.api_adset_id}
          adsetName={row.adset_name}
          recommendedOn={row.recommendation_time}
          adsetEndTime={row.end_time}
          disabled={row.channel === "google" || isTooSoonToBoost}
          boostAmount={row.boost_amount}
          submittedOn={row.start_time}
          expiredOn={row.end_time}
        />
      );
    },
  },
];

export default gridColumns;
