import React, { useState } from "react";

import { CardActionArea, IconButton } from "@mui/material";
import { InfoOutlined, DeleteForever } from "@mui/icons-material";

import { cardData } from "pages/connections/data/connectionsData";
import BodyText from "library/text/body/BodyText";
import IconText from "library/containers/IconText";
import SubHead from "library/text/headers/SubHead";
import {
  BORDER_GREY,
  FEEDBACK_SUCCESS,
  FEEDBACK_WARNING_WARNING,
  FEEDBACK_ERROR_ERROR,
  BRAND_GRAY_700,
} from "assets/palette";
import OutPointCard from "library/surface/OutPointCard";
import PlatformCardModal from "./PlatformCardModal/PlatformCardModal";
import AddCredentialsButton from "./AddCredentialsButton";
import LogoChip from "./LogoChip";

const styles = {
  container: {
    height: "auto",
    marginBottom: "20px",
  },
  cardActionArea: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "10px",
  },
  headerContainer: {
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: `1px solid ${BORDER_GREY}`,
  },
  logoChip: {
    marginRight: "10px",
  },
  listItemCard: {
    boxShadow: "none",
    border: `1px solid ${BORDER_GREY}`,
    marginBottom: "10px",
  },
  listContainer: {
    padding: "10px 10px 0 10px",
  },
  seeDetails: {
    marginLeft: "5px",
    textDecoration: "underline",
  },
  validErrorChip: {
    border: `1px solid ${FEEDBACK_ERROR_ERROR}`,
    color: FEEDBACK_ERROR_ERROR,
    padding: "2px 10px",
    borderRadius: "20px",
    marginLeft: "5px",
  },
};

export default function PlatformListTemplate({
  channel,
  whereFrom,
  tokenType,
  credentialsList = [],
  setDeleteModalState,
  setInfoModalState,
  setNeedsAttentionModalState,
  setConnectionPendingModalOpen,
}) {
  const logo = cardData[channel]?.logo;
  const validationError = credentialsList.reduce(
    (acc, credentials) => credentials.valid === false || acc,
    false,
  );

  return (
    <OutPointCard sx={styles.container}>
      <IconText sx={styles.headerContainer}>
        <IconText>
          <LogoChip
            sx={styles.logoChip}
            size="25px"
            logo={logo}
            channel={channel}
          />
          <div>
            <SubHead>{channel}</SubHead>
            <BodyText color="secondary">
              {credentialsList.length}{" "}
              {credentialsList.length > 1 ? "accounts" : "account"} added
              {validationError && (
                <span style={styles.validErrorChip}>Validation error</span>
              )}
            </BodyText>
          </div>
        </IconText>
        <AddCredentialsButton
          tokenType={tokenType}
          whereFrom={whereFrom}
          channel={channel}
        />
      </IconText>

      <div style={styles.listContainer}>
        {credentialsList.map((credentials) => (
          <PlatformListItem
            key={credentials.description}
            whereFrom={whereFrom}
            credentials={credentials}
            channel={channel}
            logo={logo}
            tokenType={tokenType}
            setDeleteModalState={setDeleteModalState}
            setInfoModalState={setInfoModalState}
            setNeedsAttentionModalState={setNeedsAttentionModalState}
            setConnectionPendingModalOpen={setConnectionPendingModalOpen}
          />
        ))}
      </div>
    </OutPointCard>
  );
}

function PlatformListItem({
  credentials,
  whereFrom,
  channel,
  logo,
  tokenType,
  setDeleteModalState,
  setInfoModalState,
  setNeedsAttentionModalState,
  setConnectionPendingModalOpen,
}) {
  const description = credentials?.description;
  const hasCredentials = credentials?.credentials;
  const hasMetadata = credentials?.metadata;
  const hasTargetMetric = credentials?.target_metric;
  const hasTargetValue = credentials?.benchmark_value;
  const hasParentId = credentials?.parent_credential_id;
  const isValid = credentials?.is_valid;
  const credentialId = credentials?.id;

  const [modalOpen, setModalOpen] = useState(false);

  const requiredFields = {
    ad: [hasCredentials, hasMetadata, hasTargetMetric, hasTargetValue],
    page: [hasCredentials, hasMetadata, hasParentId],
  };

  let status = "";
  let statusColor = "#FFFFFF";
  if (
    !requiredFields[tokenType].reduce(
      (isComplete, field) => isComplete && field,
      true,
    )
  ) {
    status = "Unfinished";
    statusColor = BRAND_GRAY_700;
  } else if (isValid) {
    status = "Connected";
    statusColor = FEEDBACK_SUCCESS;
  } else if (isValid === false) {
    status = "Needs attention";
    statusColor = FEEDBACK_ERROR_ERROR;
  } else {
    status = "Pending";
    statusColor = FEEDBACK_WARNING_WARNING;
  }

  return (
    <>
      <PlatformCardModal
        channel={channel}
        description={description}
        credentials={credentials}
        whereFrom={whereFrom}
        modalOpen={modalOpen}
        tokenType={tokenType}
        setModalOpen={setModalOpen}
      />

      <OutPointCard sx={styles.listItemCard}>
        <CardActionArea
          onClick={() => setModalOpen(true)}
          sx={styles.cardActionArea}
        >
          <IconText>
            <LogoChip
              sx={styles.logoChip}
              logo={logo}
              channel={channel}
              size="15px"
            />
            <BodyText color="secondary">
              {description} •{" "}
              <span style={{ color: statusColor, fontWeight: 700 }}>
                {status}
              </span>
            </BodyText>
            {status === "Pending" && (
              <BodyText
                sx={styles.seeDetails}
                color="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  setConnectionPendingModalOpen(true);
                }}
              >
                See details
              </BodyText>
            )}
            {status === "Needs attention" && (
              <BodyText
                sx={styles.seeDetails}
                color="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  setNeedsAttentionModalState({
                    description,
                    credentialId,
                    tokenType,
                  });
                }}
              >
                See details
              </BodyText>
            )}
          </IconText>

          <div>
            <IconButton>
              <InfoOutlined
                sx={styles.icon}
                onClick={(event) => {
                  event.stopPropagation();
                  setInfoModalState({
                    logo,
                    description,
                    channel,
                    metadata: hasMetadata,
                    status,
                    statusColor,
                  });
                }}
              />
            </IconButton>

            <IconButton>
              <DeleteForever
                sx={styles.icon}
                onClick={(event) => {
                  event.stopPropagation();
                  setDeleteModalState({
                    description,
                    credentialId,
                    tokenType,
                  });
                }}
              />
            </IconButton>
          </div>
        </CardActionArea>
      </OutPointCard>
    </>
  );
}
